import React, { useEffect, useRef, useState } from 'react';
import p5 from 'p5';
import { createClient } from '@supabase/supabase-js';
import Image from './Image';
import Flame from './Flame';
import LaserTag from './LaserTag';
import TextAnimation from './TextAnimation';



// Supabase configuration
const supabaseUrl = 'https://ruyybsfilfhauhsjfzlb.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ1eXlic2ZpbGZoYXVoc2pmemxiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTA4NjAxMTcsImV4cCI6MjAwNjQzNjExN30.yDsrNwyMJmkE0yCR02K-mcOQ7oJyY5k0Vnl89nQXVvA'; // Replace with your actual Supabase Key
const supabase = createClient(supabaseUrl, supabaseKey);

const MicrophoneTest = () => {
  const analyserCanvasRef = useRef(null);
  const [showGradient, setShowGradient] = useState(false);
  const [showLines, setShowLines] = useState(false);
  const [showTurningLines, setShowTurningLines] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showNeonEffect, setShowNeonEffect] = useState(false);
  const [flamesVisible, setFlamesVisible] = useState(false);
  const [flamePositions, setFlamePositions] = useState([]);

  const [showCubes, setShowCubes] = useState(false);
  const [cubePositions, setCubePositions] = useState([]);
  const [showLaserTag, setShowLaserTag] = useState(false);
  const [showTextAnimation, setShowTextAnimation] = useState(false);



  const toggleFlames = () => {
    setFlamesVisible(!flamesVisible);
    if (!flamesVisible) {
      setFlamePositions(generateRandomPositions(10));
    } else {
      setFlamePositions([]);
    }
  };

  const [displayedImages, setDisplayedImages] = useState([]);

  const fetchImages = async () => {
    setIsLoading(true);
    let { data, error } = await supabase
    .from('images')
    .select('*')
    .order('datetime', { ascending: false })
    .limit(20);
  
    if (error) {
      console.error('Error loading images', error);
    } else {
      const imagesWithPublicUrl = data.map((image) => ({
        ...image,
        publicUrl: `${supabaseUrl}/storage/v1/object/public/images/${image.url}`,
      }));
      setImageList(imagesWithPublicUrl);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    let interval;
    if (showImages && imageList.length > 0) {
      interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * imageList.length);
        const newImage = {
          ...imageList[randomIndex],
          position: getRandomPosition(),
          rotation: Math.random() * 20 - 10 // Store rotation here
        };
        setDisplayedImages(images => {
          // Check if the number of images is 20
          if (images.length >= 20) {
            // Remove the first image and add the new one
            return [...images.slice(1), newImage];
          } else {
            // Just add the new image
            return [...images, newImage];
          }
        });
      }, 3000); // Add new image every 3 seconds
    }
  
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [showImages, imageList]);

  const getRandomPosition = () => {
    const x = Math.random() * 80; // Leaving some margin
    const y = Math.random() * 80; // Leaving some margin
    return { left: `${x}vw`, top: `${y}vh` };
  };

  useEffect(() => {
    fetchImages();
    // Set up an interval to refresh images every 5 minutes (300,000 milliseconds)
    const refreshInterval = setInterval(fetchImages, 300000);

    // Clear the interval when the component unmounts
    return () => clearInterval(refreshInterval);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'e' || event.key === 'E') {
        setShowNeonEffect(prev => !prev);
      }
      if (event.key === 'f' || event.key === 'F') {
        toggleFlames();
      }
      if (event.key === 'g' || event.key === 'G') {
        setShowCubes(prev => !prev);
        setCubePositions(generateRandomPositions(15));
      }
      if (event.key === 'h' || event.key === 'H') {
        setShowLaserTag(prev => !prev);
      }
      if (event.key === 'i' || event.key === 'I') {
        setShowTextAnimation(prev => !prev);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [flamesVisible, showCubes]);

  useEffect(() => {
    const sketch = (p) => {
      let audioCtx, analyser, dataArray;
      let num = 2000;
      let range = 50;
      let lines = 10;
      let ax = [];
      let ay = [];
      let colorShift = 0;
      let c1, c2;
      let colorChangeInterval = 100;

      // Variables for the turning lines
      let turningLines = [];
      let turningLinesCount = 50;
      let turningLineLength = 200;
      let turnInterval = 60;

      p.setup = async () => {
        p.createCanvas(p.windowWidth, p.windowHeight).parent(analyserCanvasRef.current);
        p.colorMode(p.HSB, 255);
        p.frameRate(30);

        // Initialize array values for each line
        for (let j = 0; j < lines; j++) {
          ax[j] = [];
          ay[j] = [];
          for (let i = 0; i < num; i++) {
            ax[j][i] = p.width / 2;
            ay[j][i] = p.height / 2;
          }
        }

        // Initialize gradient colors
        c1 = p.color(p.random(255), 255, 255);
        c2 = p.color(p.random(255), 255, 255);

        // Initialize turning lines
        for (let i = 0; i < turningLinesCount; i++) {
          turningLines.push({
            x: p.random(p.width),
            y: p.random(p.height),
            horizontal: p.random([true, false]),
            step: p.random(3, 5)
          });
        }

        // Audio setup
        if (navigator.mediaDevices.getUserMedia) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioCtx = new AudioContext();
            analyser = audioCtx.createAnalyser();
            analyser.fftSize = 2048;
            const audioSrc = audioCtx.createMediaStreamSource(stream);
            audioSrc.connect(analyser);
            dataArray = new Uint8Array(analyser.frequencyBinCount);
          } catch (err) {
            console.error('Error accessing audio input:', err);
          }
        }
      };

      const loudSoundDetected = () => {
        let sum = dataArray.reduce((a, b) => a + b, 0);
        let average = sum / dataArray.length;
        return average > 128;
      };

      const createGradientBackground = () => {
        for (let y = 0; y < p.height; y++) {
          let inter = p.map(y, 0, p.height, 0, 1);
          let c = p.lerpColor(c1, c2, inter);
          p.stroke(c);
          p.line(0, y, p.width, y);
        }
      };

      p.draw = () => {
        p.clear(); // Clear the canvas on each frame

        if (showGradient) {
          if (p.frameCount % colorChangeInterval === 0) {
            c1 = p.color(p.random(255), 255, 255);
            c2 = p.color(p.random(255), 255, 255);
          }
          
          createGradientBackground();
        }

        if (showLines) {
          if (dataArray) {
            analyser.getByteFrequencyData(dataArray);
            if (loudSoundDetected()) {
              colorShift = (colorShift + 10) % 256;
            }
          }

          for (let j = 0; j < lines; j++) {
            for (let i = 1; i < num; i++) {
              ax[j][i - 1] = ax[j][i];
              ay[j][i - 1] = ay[j][i];
            }

            ax[j][num - 1] += p.random(-range, range);
            ay[j][num - 1] += p.random(-range, range);
            ax[j][num - 1] = p.constrain(ax[j][num - 1], 0, p.width);
            ay[j][num - 1] = p.constrain(ay[j][num - 1], 0, p.height);

            for (let i = 1; i < num; i++) {
              let hue = (colorShift + j * 25) % 256;
              p.stroke(hue, 255, 255);
              p.strokeWeight(2);
              p.line(ax[j][i - 1], ay[j][i - 1], ax[j][i], ay[j][i]);
            }
          }
        }

        if (showTurningLines) {
          for (let i = 0; i < turningLines.length; i++) {
            let line = turningLines[i];
            p.stroke(255);
            p.strokeWeight(2);

            if (line.horizontal) {
              p.line(line.x, line.y, line.x + turningLineLength, line.y);
              line.x += line.step;
            } else {
              p.line(line.x, line.y, line.x, line.y + turningLineLength);
              line.y += line.step;
            }

            if (p.frameCount % turnInterval === 0) {
              line.horizontal = !line.horizontal;
            }

            // Wrap around the canvas
            line.x = (line.x + p.width) % p.width;
            line.y = (line.y + p.height) % p.height;
          }
        }
      };

      p.keyPressed = () => {
        if (p.key === 'a' || p.key === 'A') {
          setShowGradient(!showGradient);
        }
        if (p.key === 'b' || p.key === 'B') {
          setShowLines(!showLines);
        }
        if (p.key === 'c' || p.key === 'C') {
          setShowTurningLines(!showTurningLines);
        }
        if (p.key === 'd' || p.key === 'D') {
          console.log('Toggling showImages:', !showImages); // Logging
          setShowImages(!showImages); // Toggle image display
        }
        // if (p.key === 'e' || p.key === 'E') {
        //   console.log('Toggling showNeonEffect:', !showImages); // Logging
        //   setShowNeonEffect(prev => !prev);
        // }
      };
    };




    let myp5 = new p5(sketch, analyserCanvasRef.current);

    return () => {
      myp5.remove();
    };
  }, [showGradient, showLines, showTurningLines, showImages]);

  const neonGlowAnimation = `@keyframes neonGlow {
    0%, 100% {
      box-shadow: 0 0 30px 20px rgba(255, 0, 0, 0.8), 0 0 60px 30px rgba(255, 20, 147, 0.8), 0 0 90px 40px rgba(255, 105, 180, 0.8);
    }
    50% {
      box-shadow: 0 0 15px 10px rgba(255, 0, 0, 0.8), 0 0 30px 15px rgba(255, 20, 147, 0.8), 0 0 45px 20px rgba(255, 105, 180, 0.8);
    }
  }`;

  const cubes = showCubes
  ? cubePositions.map((pos, idx) => (
      <div key={idx} style={{ position: 'absolute', ...pos }}>
      </div>
    ))
  : null;
  
  const imageElements = displayedImages.map((img, idx) => {
    return (
      <div key={idx} style={{ ...img.position, position: 'absolute', transform: `rotate(${img.rotation}deg)` }}>
        <Image
          src={img.publicUrl}
          alt={`Image ${idx}`}
          style={{ 
            width: '150px', 
            height: 'auto', 
            boxShadow: showNeonEffect ? '0 0 30px 20px rgba(255, 0, 0, 0.8), 0 0 60px 30px rgba(255, 20, 147, 0.8), 0 0 90px 40px rgba(255, 105, 180, 0.8)' : 'none',
            animation: showNeonEffect ? 'neonGlow 1.5s ease-in-out infinite alternate' : 'none'
          }}
        />
      </div>
    );
  });

  const renderCubes = () => {
    return cubePositions.map((pos, idx) => (
      <pre key={idx} style={{
        position: 'absolute',
        ...pos,
        color: getDarkerNeonColor(),
        fontFamily: 'monospace',
        fontWeight: 'bold', // Makes the text bold
        textShadow: '0 0 10px currentColor', // Adds a glow effect
      }}>
        {cube.join('\n')}
      </pre>
    ));
  };
  
  // Function to get a random darker neon color
  const getDarkerNeonColor = () => {
    const darkerNeonColors = [
      'rgba(255, 0, 0, 1)', // Dark Red
      'rgba(255, 20, 147, 1)', // Dark Pink
      'rgba(255, 105, 180, 1)', // Dark Hot Pink
      'rgba(0, 255, 0, 1)', // Dark Green
      'rgba(0, 255, 255, 1)', // Dark Cyan
      'rgba(255, 255, 0, 1)' // Dark Yellow
    ];
    return darkerNeonColors[Math.floor(Math.random() * darkerNeonColors.length)];
  };
  
  return (
    <div>
      <div ref={analyserCanvasRef}></div>
      {showLaserTag && <div><LaserTag /><LaserTag /><LaserTag /><LaserTag /><LaserTag /><LaserTag /><LaserTag /></div>}
      {isLoading ? <div>Loading...</div> : null}
      {imageElements}
      {flamePositions.map((pos, idx) => (
        <div key={idx} style={{ position: 'absolute', ...pos }}>
          <Flame />
        </div>
      ))}
      {showCubes && renderCubes()}
      {showTextAnimation && <TextAnimation />}
      
    </div>
  );
};

const modalStyle = {
  position: "fixed",
  zIndex: 9999,
  paddingTop: "100px",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  overflow: "auto",
  backgroundColor: "rgba(0,0,0,0.4)",
  filter: "blur(100px)"
};

const cube = [
  "      +--------+",
  "     /        /|",
  "    /        / |",
  "   +--------+  |",
  "   |        |  |",
  "   |        |  +",
  "   |        | /",
  "   |        |/",
  "   +--------+"
];


const generateRandomPositions = (count) => {
  let positions = [];
  let gridRows = Math.ceil(Math.sqrt(count));
  let gridCols = Math.ceil(count / gridRows);

  for (let row = 0; row < gridRows; row++) {
    for (let col = 0; col < gridCols; col++) {
      if (positions.length >= count) {
        break;
      }
      let x = (100 / gridCols) * col + (Math.random() * (100 / gridCols));
      let y = (100 / gridRows) * row + (Math.random() * (100 / gridRows));
      positions.push({
        left: `${x}vw`,
        top: `${y}vh`
      });
    }
  }
  return positions;
};

export default MicrophoneTest;