"use client"

import React from 'react';

const Image = ({ src, style, alt, onClick }) => {
  return (
    <div className="image-container" style={style}>
      <img src={src} style={{ objectFit: 'cover', width: '100%', height: '100%' }} alt={alt} onClick={onClick} />
    </div>
  );
};

export default Image;