import React, { useEffect, useState } from 'react';
import './LaserTag.css'; // Import the CSS file here

const LaserTag = () => {
  // State to store the random position
  const [position, setPosition] = useState({ top: '50%', left: '50%' });

  // Function to generate a random position
  const getRandomPosition = () => {
    const top = Math.random() * 100;
    const left = Math.random() * 100;
    return { top: `${top}vh`, left: `${left}vw` };
  };

  // Set a random position when the component mounts
  useEffect(() => {
    const newPosition = getRandomPosition();
    setPosition(newPosition);
  }, []);

  // Inline styles for positioning the animation container
  const containerStyles = {
    ...position,
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    maxWidth: '100%',
  };

  return (
    <div className="animation-container" style={containerStyles}>
      <div className="lightning-container">
        <div className="lightning white"></div>
        <div className="lightning red"></div>
      </div>
      <div className="boom-container">
        <div className="shape circle big white"></div>
        <div className="shape circle white"></div>
        <div className="shape triangle big yellow"></div>
        <div className="shape disc white"></div>
        <div className="shape triangle blue"></div>
      </div>
      <div className="boom-container second">
        <div className="shape circle big white"></div>
        <div className="shape circle white"></div>
        <div className="shape disc white"></div>
        <div className="shape triangle blue"></div>
      </div>
    </div>
  );
};

export default LaserTag;