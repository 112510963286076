import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';

// Supabase configuration
const supabaseUrl = 'https://ruyybsfilfhauhsjfzlb.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ1eXlic2ZpbGZoYXVoc2pmemxiIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY5MDg2MDExNywiZXhwIjoyMDA2NDM2MTE3fQ.MNu7iiDeDLKDkdtkANYYCq44eP1kb4UJ3SDk_7kMB9A' 
const supabase = createClient(supabaseUrl, supabaseKey);

const UploadComponent = () => {
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);

  const uploadImage = async (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    setUploading(true);
    const file = event.target.files[0];

    if (!file.type.startsWith('image/')) {
      alert('You can only upload image files.');
      setUploading(false);
      return;
    }

    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;

    let { error: uploadError } = await supabase.storage
      .from('images')
      .upload(fileName, file);

    if (uploadError) {
      console.error('Error uploading image: ', uploadError);
      alert('Error uploading image.');
    } else {
      let { error: insertError } = await supabase
        .from('images')
        .insert([{ url: fileName }]);

      if (insertError) {
        console.error('Error saving image info: ', insertError);
      } else {
        setSuccess(true);
      }
    }

    setUploading(false);
  };

  return (
    <div style={styles.container}>
      {success ? (
        <div style={styles.successMessage}>
          <h2>Upload Successful!</h2>
          <p>Your image has been uploaded.</p>
        </div>
      ) : (
        <div style={styles.modalContainer}>
          <div style={styles.modalContent}>
            <h2 style={styles.modalHeader}>🌈Upload Photo to Hive Mind🧠</h2>
            {uploading ? (
              <p>Uploading...</p>
            ) : (
              <div>
                <label htmlFor="image-upload" style={styles.uploadLabel}>
                  <input
                    id="image-upload"
                    type="file"
                    onChange={uploadImage}
                    disabled={uploading}
                    style={styles.uploadInput}
                  />
                  Choose a photo king/queen...
                </label>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// Styles
const styles = {
  container: {
    textAlign: 'center',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#ff00ff', // Bright color background
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#0f0f0f', // Dark background
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '90%',
    textAlign: 'center',
  },
  modalHeader: {
    color: '#7df9ff', // Neon text
    marginBottom: '20px',
    textShadow: '0 0 10px #7df9ff',
  },
  uploadLabel: {
    display: 'block',
    padding: '10px 20px',
    backgroundColor: '#ff00ff', // Bright color background
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: '0 0 10px #ff00ff',
    margin: 'auto',
    width: 'fit-content',
  },
  uploadInput: {
    display: 'none',
  },
  successMessage: {
    backgroundColor: '#ff00ff', // Bright color background
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '90%',
    textAlign: 'center',
    margin: '0 auto',
    marginTop: '20px',
    boxShadow: '0 0 10px #ff00ff',
  },
};

export default UploadComponent;
