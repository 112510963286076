import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sketch from './components/Sketch';
import UploadComponent from './components/Upload';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Sketch />} />
          <Route path="/upload" element={<UploadComponent/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
