import React, { useEffect, useState } from 'react';
import './TextAnimation.css';

const TextAnimation = () => {
  const texts = [
    "FUCK ME", "I LOVE YOU", "FUCK ME HARDER", "FUCK ME DADDY", "FUCK ME MOMMY", "MELON PARTY", "MELON PARTY", "MELON PARTY"
  ];

  const [index, setIndex] = useState(0);
  const [morph, setMorph] = useState(0);
  const morphTime = 4; // Increase this value to extend the time each word is displayed
  const cooldownTime = 0.50; // Cooldown time between morphs

  useEffect(() => {
    const updateText = () => {
      const fraction = morph / morphTime;
      if (fraction <= 1) {
        setMorph(m => m + 0.005); // Adjust this value to control the speed of the morphing effect
      } else {
        setMorph(0); // Reset morph
        setIndex(i => (i + 1) % texts.length); // Go to next text
      }

      // Update styles
      const text1 = document.getElementById("text1");
      const text2 = document.getElementById("text2");

      text1.textContent = texts[index];
      text2.textContent = texts[(index + 1) % texts.length];

      text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      text1.style.opacity = `${Math.pow(1 - fraction, 0.4) * 100}%`;

      text2.style.filter = `blur(${Math.min(8 / (1 - fraction) - 8, 100)}px)`;
      text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
    };

    const intervalId = setInterval(updateText, 50); // Update every 50 milliseconds

    return () => clearInterval(intervalId);
  }, [index, morph, texts]);

  return (
    <div id="container">
      <span id="text1"></span>
      <span id="text2"></span>
      <svg id="filters">
        <defs>
          <filter id="threshold">
            <feColorMatrix in="SourceGraphic" type="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 255 -140" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default TextAnimation;
